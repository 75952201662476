import store from "@/state/store";
import { COMMON } from "@/constants/common";
import { ROUTE } from "@/constants/routes";
import { getUserDetails } from "@/helpers/user/user.service";
import { getSettings } from "@/helpers/settings/settings.service";
import Promise from "lodash/_Promise";

const beforeResolveByRoles = async (roles, { routeTo, routeFrom, next }) => {
  // const isDevelopment = process.env.NODE_ENV === "development";

  // if (isDevelopment) return next();

  let companyId = sessionStorage.getItem(COMMON.COMPANY);

  if (!companyId) companyId = localStorage.getItem(COMMON.COMPANY);

  let user = store.getters["user/getUserDetails"];

  if (!user) {
    user = await getUserDetails();
  }

  let finalRole = ["Super Admin", "Company Admin"].includes(user.role) ? user.role : "Company Contact";

  if (!roles.includes(finalRole)) {
    return next("/");
  }

  next();
};

export default [
  {
    path: "/404",
    name: "NotFound",
    meta: {
      title: "Not Found",
    },
  },
  {
    path: "/login",
    name: "LoginRedirect",
    meta: {
      title: "LoginRedirect",
      beforeResolve(routeTo, routeFrom, next) {
        let companyId = sessionStorage.getItem(COMMON.COMPANY);

        if (!companyId) companyId = localStorage.getItem(COMMON.COMPANY);

        next(`/${companyId}/login`);
      },
    },
  },
  {
    path: "/:company_id/login",
    name: "Login",
    component: () => import("../views/auth/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        const redirectTo = routeTo?.redirectedFrom?.fullPath;
        const accessToken = localStorage.getItem(COMMON.ACCESS_TOKEN);

        if (redirectTo) {
          sessionStorage.setItem("redirectTo", redirectTo);
        }

        const hasNoCompanyId =
          routeTo.params.company_id === "null" ||
          routeTo.params.company_id === "undefined";

        let companyId = sessionStorage.getItem(COMMON.COMPANY);

        if (!companyId) companyId = localStorage.getItem(COMMON.COMPANY);

        if (!routeTo.query.token) {
          // If the user is already logged in
          if (accessToken) {
            // Redirect to the home page instead
            next({ name: "Dashboard" });
          } else {
            next(hasNoCompanyId ? `/${companyId}/login` : undefined);
          }
        } else {
          next(hasNoCompanyId ? `/${companyId}/login` : undefined);
        }
      },
    },
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("../views/account/register.vue"),
  //   meta: {
  //     title: "Register",
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    redirect: "/jobs",
    name: "Dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    children: [
      {
        path: "/jobs",
        name: "Jobs",
        component: () => import("../views/pages/jobs/jobs.vue"),
        meta: {
          title: "Jobs",
          async beforeResolve(routeTo, routeFrom, next) {
            let companyId = sessionStorage.getItem(COMMON.COMPANY);

            if (!companyId) companyId = localStorage.getItem(COMMON.COMPANY);

            let user = store.getters["user/getUserDetails"];
            let settings = store.getters["settings/getSettings"];

            if (!user) {
              user = await getUserDetails();
            }

            if (
              user.role !== "Super Admin" &&
              user.role !== "Company Admin" &&
              !settings?.shift?.enabled
            ) {
              next(ROUTE.TIMESHEETS.BASE);
            }

            next();
          },
        },
      },
      {
        path: ROUTE.TIMESHEETS.BASE,
        name: "Timesheets",
        component: () => import("../views/pages/timesheets/index.vue"),
        children: [
          {
            path: "",
            name: "TimesheetList",
            component: () => import("../views/pages/timesheets/timesheets.vue"),
          },
          {
            path: "view/:id",
            name: "TimesheetPage",
            component: () => import("../views/pages/timesheets/timesheet.vue"),
          },
          {
            path: "edit/:id",
            name: "TimesheetEdit",
            component: () => import("../views/pages/timesheets/timesheet.vue"),
          },
        ],
      },
      // {
      //   path: ROUTE.MESSAGES.BASE,
      //   name: "Messaging",
      //   children: [
      //     {
      //       path: "",
      //       name: "MyInbox",
      //       component: () => import("../views/pages/inbox/index.vue"),
      //     },
      //   ],
      // },
      {
        path: ROUTE.WHOSWORKING.BASE,
        name: "WhosWorking",
        component: () => import("../views/pages/whos-working/whos-working.vue"),
      },
      {
        path: ROUTE.SETTINGS.ADMIN.BASE,
        name: "AdminSettings",
        redirect: ROUTE.SETTINGS.ADMIN.DEFAULT,
        component: () => import("../views/pages/settings/settings.vue"),
        children: [
          {
            path: ROUTE.SETTINGS.ADMIN.CLIENT_PORTAL,
            name: "AdminSettings.ClientPortal",
            component: () => import("../views/pages/settings/client-portal"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.SHIFT,
            name: "AdminSettings.Shift",
            component: () => import("../views/pages/settings/shift"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.CANDIDATES,
            name: "AdminSettings.Candidates",
            component: () => import("../views/pages/settings/candidate"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.NOTIFICATIONS,
            name: "AdminSettings.Notifications",
            component: () => import("../views/pages/settings/notifications"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.MOBILE_APP.BASE,
            name: "AdminSettings.MobileAppSettings",
            component: () => import("../views/pages/settings/mobile-app"),
            redirect: ROUTE.SETTINGS.ADMIN.MOBILE_APP.NEW_USER_JOURNEY.BASE,
            children: [
              {
                path: ROUTE.SETTINGS.ADMIN.MOBILE_APP.NEW_USER_JOURNEY.BASE,
                name: "AdminSettings.MobileAppSettings.NewUserJourney",
                component: () =>
                  import(
                    "../views/pages/settings/mobile-app/components/new-user-journey"
                  ),
                children: [
                  {
                    path: "",
                    name: "AdminSettings.MobileAppSettings.NewUserJourney.List",
                    component: () =>
                      import(
                        "../views/pages/settings/mobile-app/components/new-user-journey/components/menu-list.vue"
                      ),
                  },
                  {
                    path: ROUTE.SETTINGS.ADMIN.MOBILE_APP.NEW_USER_JOURNEY
                      .LANDING_PAGE,
                    name: "AdminSettings.MobileAppSettings.NewUserJourney.LandingPage",
                    component: () =>
                      import(
                        "../views/pages/settings/mobile-app/components/new-user-journey/components/landing-page.vue"
                      ),
                  },
                  {
                    path: ROUTE.SETTINGS.ADMIN.MOBILE_APP.NEW_USER_JOURNEY
                      .KEYWORDS,
                    name: "AdminSettings.MobileAppSettings.NewUserJourney.Keywords",
                    component: () =>
                      import(
                        "../views/pages/settings/mobile-app/components/new-user-journey/components/keywords.vue"
                      ),
                  },
                  {
                    path: ROUTE.SETTINGS.ADMIN.MOBILE_APP.NEW_USER_JOURNEY
                      .INTERVIEW,
                    name: "AdminSettings.MobileAppSettings.NewUserJourney.Interview",
                    component: () =>
                      import(
                        "../views/pages/settings/mobile-app/components/new-user-journey/components/interview.vue"
                      ),
                  },
                  {
                    path: ROUTE.SETTINGS.ADMIN.MOBILE_APP.NEW_USER_JOURNEY.CTA,
                    name: "AdminSettings.MobileAppSettings.NewUserJourney.CTA",
                    component: () =>
                      import(
                        "../views/pages/settings/mobile-app/components/new-user-journey/components/cta.vue"
                      ),
                  },
                ],
                meta: {
                  tabName: "new-user-journey"
                }
              },
              {
                path: ROUTE.SETTINGS.ADMIN.MOBILE_APP.MAINTENANCE_MODE.BASE,
                name: "AdminSettings.MobileAppSettings.MaintenanceMode",
                component: () =>
                  import(
                    "../views/pages/settings/mobile-app/components/maintenance-mode"
                  ),
                children: [
                  {
                    path: "",
                    name: "AdminSettings.MobileAppSettings.MaintenanceMode",
                    component: () =>
                      import(
                        "../views/pages/settings/mobile-app/components/maintenance-mode/index.vue"
                      ),
                  },
                ],
                meta: {
                  tabName: "maintenance-mode"
                }
              },
            ],
          },
          {
            path: ROUTE.SETTINGS.ADMIN.SCHEDULING_TIMESHEETS,
            name: "AdminSettings.SchedulingTimesheets",
            component: () =>
              import("../views/pages/settings/scheduling-timesheets"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.USERS,
            name: "AdminSettings.Users",
            component: () => import("../views/pages/settings/users"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.ROLES,
            name: "AdminSettings.Roles",
            component: () => import("../views/pages/settings/users"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.BRANCHES,
            name: "AdminSettings.Branches",
            component: () => import("../views/pages/settings/users"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.CUSTOMREPORTS,
            name: "AdminSettings.CustomReports",
            component: () => import("../views/pages/settings/custom-reports"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.COMPANIES,
            name: "AdminSettings.Companies",
            component: () => import("../views/pages/settings/company"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.JOBS,
            name: "AdminSettings.Jobs",
            component: () => import("../views/pages/settings/jobs"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.INTEGRATIONS,
            name: "AdminSettings.Integrations",
            component: () => import("../views/pages/settings/integrations"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.ONBOARDING,
            name: "AdminSettings.Onboarding",
            component: () => import("../views/pages/settings/onboarding"),
          },
          {
            path: ROUTE.SETTINGS.ADMIN.IMPORT,
            name: "AdminSettings.Import",
            component: () => import("../views/pages/settings/import"),
          },
        ],
      },
      {
        path: ROUTE.COMPANIES.BASE,
        name: "Companies",
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            return beforeResolveByRoles(["Super Admin"], {
              routeTo,
              routeFrom,
              next,
            });
          },
        },
        children: [
          {
            path: "",
            name: "Companies.List",
            component: () => import("../views/pages/companies/feed"),
          },
          {
            path: ROUTE.COMPANIES.ID,
            name: "Companies.Profile",
            component: () => import("../views/pages/companies/profile"),
            children: [
              {
                path: ROUTE.COMPANIES.PROFILE.NOTES,
                name: "Companies.Profile.Notes",
                component: () =>
                  import("../views/pages/companies/profile/pages/notes"),
              },
              {
                path: ROUTE.COMPANIES.PROFILE.CONTACTS,
                name: "Companies.Profile.Contacts",
                component: () =>
                  import("../views/pages/companies/profile/pages/contacts"),
              },
              {
                path: ROUTE.COMPANIES.PROFILE.LOCATIONS,
                name: "Companies.Profile.Locations",
                component: () =>
                  import("../views/pages/companies/profile/pages/locations"),
              },
              {
                path: ROUTE.COMPANIES.PROFILE.KIOSKS,
                name: "Companies.Profile.Kiosks",
                component: () =>
                  import("../views/pages/companies/profile/pages/kiosks"),
              },
            ],
          },
          {
            path: ROUTE.COMPANIES.UPDATE,
            name: "Companies.Update",
            component: () => import("../views/pages/companies/update"),
          },
        ],
      },
      {
        path: ROUTE.CANDIDATES.BASE,
        name: "Candidates",
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            return beforeResolveByRoles(["Super Admin", "Company Admin"], {
              routeTo,
              routeFrom,
              next,
            });
          },
        },
        children: [
          {
            path: "",
            name: "Candidates.List",
            component: () => import("../views/pages/candidates/feed"),
          },
          {
            path: ROUTE.CANDIDATES.ID,
            name: "Candidates.Profile",
            component: () => import("../views/pages/candidates/profile"),
            children: [
              {
                path: ROUTE.CANDIDATES.PROFILE.NOTES,
                name: "Candidates.Profile.Notes",
                component: () =>
                  import("../views/pages/candidates/profile/pages/notes"),
              },
              {
                path: ROUTE.CANDIDATES.PROFILE.DOCUMENTS,
                name: "Candidates.Profile.Documents",
                component: () =>
                  import("../views/pages/candidates/profile/pages/documents"),
              },
              {
                path: ROUTE.CANDIDATES.PROFILE.PLACEMENTS,
                name: "Candidates.Profile.Placements",
                component: () =>
                  import("../views/pages/candidates/profile/pages/placements"),
              },
              {
                path: ROUTE.CANDIDATES.PROFILE.RESUME,
                name: "Candidates.Profile.Resume",
                component: () =>
                  import("../views/pages/candidates/profile/pages/resume"),
              },
              {
                path: ROUTE.CANDIDATES.PROFILE.AVAILABILITY,
                name: "Candidates.Profile.Availability",
                component: () =>
                  import(
                    "../views/pages/candidates/profile/pages/availability"
                  ),
              },
              {
                path: ROUTE.CANDIDATES.PROFILE.PREFERENCES,
                name: "Candidates.Profile.Preferences",
                component: () =>
                  import(
                    "../views/pages/candidates/profile/pages/preferences"
                  ),
              }
            ],
          },
          {
            path: ROUTE.CANDIDATES.UPDATE,
            name: "Candidates.Update",
            component: () => import("../views/pages/candidates/update"),
          },
        ],
      },
      {
        path: ROUTE.ONBOARDING.BASE,
        name: "Onboarding",
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            return beforeResolveByRoles(["Super Admin", "Company Admin"], {
              routeTo,
              routeFrom,
              next,
            });
          },
        },
        children: [
          {
            path: "",
            name: "Onboarding.List",
            component: () => import("../views/pages/onboarding/feed"),
          },
        ],
      },
      {
        path: ROUTE.PLACEMENTS.BASE,
        name: "Placements",
        component: () => import("../views/pages/placements/index.vue"),
        children: [
          {
            path: "",
            name: "Placements.List",
            component: () => import("../views/pages/placements/placements.vue"),
          },
          // {
          //   path: "old",
          //   name: "Placements.List (OLD)",
          //   component: () =>
          //     import("../views/pages/placements/placements-list.vue"),
          // },
        ],
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            return beforeResolveByRoles(["Super Admin", "Company Admin"], {
              routeTo,
              routeFrom,
              next,
            });
          },
        },
      },
      {
        path: ROUTE.ORDERS.BASE,
        name: "Orders",
        component: () => import("../views/pages/orders/index.vue"),
        children: [
          {
            path: "",
            name: "Orders.List",
            component: () => import("../views/pages/orders/orders.vue"),
          },
        ],
        meta: {
          async beforeResolve(routeTo, routeFrom, next) {
            // Set route loading state
            store.commit("route/SET_LOADING", true);

            const user = await getUserDetails()
            const settings = await getSettings(user?.company_id)
            const roles = ["Super Admin", "Company Admin"];

            if(settings?.orders?.show_orders_to_client == true) {
              roles.push("Company Contact");
            }

            store.commit("route/SET_LOADING", false);
            return beforeResolveByRoles(roles, {
              routeTo,
              routeFrom,
              next,
            });
          },
        },
      },
      {
        path: ROUTE.MESSAGING.PUSH_NOTIFICATION,
        name: "Notifications",
        component: () => import("../views/pages/messaging/push-notifications/index.vue"),
        children: [
          {
            path: "",
            name: "Notifications.List",
            component: () => import("../views/pages/messaging/push-notifications/notification-list.vue"),
          },
        ],
        meta: {
          async beforeResolve(routeTo, routeFrom, next) {
            // Set route loading state
            store.commit("route/SET_LOADING", true);

            const user = await getUserDetails()
            const settings = await getSettings(user?.company_id)
            const roles = ["Super Admin", "Company Admin"];

            store.commit("route/SET_LOADING", false);
            return beforeResolveByRoles(roles, {
              routeTo,
              routeFrom,
              next,
            });
          },
        },
      },
      {
        path: ROUTE.CONTACTS.BASE,
        name: "CompanyContact",
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            return beforeResolveByRoles(["Super Admin", "Company Admin"], {
              routeTo,
              routeFrom,
              next,
            });
          },
        },
        children: [
          {
            path: "",
            name: "CompanyContact.List",
            component: () => import("../views/pages/contacts/feed"),
          },
          {
            path: ROUTE.CONTACTS.ID,
            name: "CompanyContact.Profile",
            component: () => import("../views/pages/contacts/profile"),
            children: [
              {
                path: ROUTE.CONTACTS.PROFILE.NOTES,
                name: "CompanyContact.Profile.Notes",
                component: () =>
                  import("../views/pages/contacts/profile/pages/notes"),
              },
              {
                path: ROUTE.CONTACTS.PROFILE.LOCATIONS,
                name: "CompanyContact.Profile.Locations",
                component: () =>
                  import("../views/pages/contacts/profile/pages/locations"),
              },
              {
                path: ROUTE.CONTACTS.PROFILE.SETTINGS,
                name: "CompanyContact.Profile.Settings",
                component: () =>
                  import("../views/pages/contacts/profile/pages/settings"),
              },
            ],
          },
          {
            path: ROUTE.CONTACTS.UPDATE,
            name: "CompanyContact.Update",
            component: () => import("../views/pages/contacts/update"),
          },
        ],
      },
    ],
  },
  {
    path: "/:company_id/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // localStorage.clear();
        // sessionStorage.clear();
        localStorage.removeItem(COMMON.USER);
        localStorage.removeItem(COMMON.ACCESS_TOKEN);
        sessionStorage.removeItem(COMMON.SETTINGS);
        sessionStorage.removeItem(COMMON.USER);
        next();
      },
    },
    component: () => import("../views/auth/logout/basic"),
  },
  {
    path: ROUTE.PUBLIC.JOBS,
    name: "Job Listing",
    component: () => import("../views/public/jobs.vue"),
    meta: {
      title: "Job Listing",
      authRequired: false,
    },
  },
];
